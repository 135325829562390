var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('Header',{attrs:{"type":2}}),_c('div',{staticClass:"quota-main"},[(_vm.type === 1)?_c('div',{staticClass:"quota-box"},[_c('div',{staticClass:"title"},[_vm._v("甄别")]),_vm._m(0),_vm._m(1),_c('div',{staticClass:"blod"},[_vm._v("很遗憾，您被甄别了！")]),_c('div',{staticClass:"explain"},[_vm._v(" 根据您的作答信息，您不属于本次调查的目标人群。 感谢您的积极参与！ ")])]):_vm._e(),(_vm.type === 2)?_c('div',{staticClass:"quota-box"},[_c('div',{staticClass:"title"},[_vm._v("配额满")]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"blod"},[_vm._v("很遗憾，配额满了！")]),_c('div',{staticClass:"explain"},[_vm._v(" 根据您的作答信息，已经配额满了。 感谢您的积极参与！ ")])]):_vm._e(),(_vm.type === 3)?_c('div',{staticClass:"quota-box"},[_c('div',{staticClass:"title"},[_vm._v("完成")]),_vm._m(4),_vm._m(5),_c('div',{staticClass:"blod"},[_vm._v("恭喜您，您已经完成全部问卷！")]),_c('div',{staticClass:"explain"},[_vm._v(" 根据您的作答信息，您已经完成了全部作答问卷。 感谢您的积极参与！ ")])]):_vm._e()]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"explain-title"},[_vm._v("甄别")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/images/screen.png"),"alt":"图片"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"explain-title"},[_vm._v("配额满")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/images/quota.png"),"alt":"图片"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"explain-title"},[_vm._v("完成")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/images/complete.png"),"alt":"图片"}})])
}]

export { render, staticRenderFns }