<template>
  <div class="home">
    <Header :type="2"></Header>
    <div class="quota-main">
      <div class="quota-box" v-if="type === 1">
        <div class="title">甄别</div>
        <div class="title-box">
          <div class="explain-title">甄别</div>
        </div>
        <div class="img-box">
          <img src="@/assets/images/screen.png" alt="图片" />
        </div>
        <div class="blod">很遗憾，您被甄别了！</div>
        <div class="explain">
          根据您的作答信息，您不属于本次调查的目标人群。 感谢您的积极参与！
        </div>
      </div>
      <div class="quota-box" v-if="type === 2">
        <div class="title">配额满</div>
        <div class="title-box">
          <div class="explain-title">配额满</div>
        </div>
        <div class="img-box">
          <img src="@/assets/images/quota.png" alt="图片" />
        </div>
        <div class="blod">很遗憾，配额满了！</div>
        <div class="explain">
          根据您的作答信息，已经配额满了。 感谢您的积极参与！
        </div>
      </div>
      <div class="quota-box" v-if="type === 3">
        <div class="title">完成</div>
        <div class="title-box">
          <div class="explain-title">完成</div>
        </div>
        <div class="img-box">
          <img src="@/assets/images/complete.png" alt="图片" />
        </div>
        <div class="blod">恭喜您，您已经完成全部问卷！</div>
        <div class="explain">
          根据您的作答信息，您已经完成了全部作答问卷。 感谢您的积极参与！
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
export default {
  components: { Header, Footer },
  data() {
    return {
      type: 3,
    };
  },
  methods: {},
  created() {
    this.type = parseInt(this.$route.query.type) || 1;
    console.log("this.$route.query.type", this.$route.query.type);
    if (this.type === 1) document.title = "被甄别";
    if (this.type === 2) document.title = "配额满";
    if (this.type === 3) document.title = "成功完成";
  },
};
</script>
<style lang="scss" scoped>
.quota-main {
  width: 100%;
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  .quota-box {
    display: flex;
    flex-direction: column;
    .title {
      width: 93px;
      padding: 0 20px;
      height: 37px;
      background: #4578ff;
      border-radius: 19px 19px 0px 19px;
      text-align: center;
      line-height: 37px;
      color: #fff;
    }
    .title-box {
      display: none;
      .explain-title {
        width: 70px;
        height: 28px;
        background: #4578ff;
        border-radius: 14px 14px 0px 14px;
        text-align: center;
        line-height: 28px;
        color: #fff;
        font-size: 13px;
      }
    }
    .img-box {
      width: 294px;
      height: 250px;
      margin-left: 72px;
      margin-right: 72px;
      margin-top: 36px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .blod {
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      margin-top: 42px;
    }
    .explain {
      text-align: center;
      width: 368px;
      font-size: 16px;
      margin-left: 36px;
      margin-top: 28px;
      line-height: 24px;
    }
  }
}
</style>
<style  lang="scss" scoped>
@media only screen and (max-width: 750px) {
  .quota-main {
    width: 100%;
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    .quota-box {
      display: flex;
      flex-direction: column;
      .title {
        width: 93px;
        padding: 0 20px;
        height: 37px;
        background: #4578ff;
        border-radius: 19px 19px 0px 19px;
        text-align: center;
        line-height: 37px;
        color: #fff;
      }
      .img-box {
        width: 294px;
        height: 250px;
        margin-left: 72px;
        margin-right: 72px;
        margin-top: 36px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .blod {
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        margin-top: 42px;
      }
      .explain {
        text-align: center;
        width: 368px;
        font-size: 16px;
        margin-left: 36px;
        margin-top: 28px;
        line-height: 24px;
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .quota-main {
    width: 100%;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    .quota-box {
      display: flex;
      flex-direction: column;
      .title {
        width: 63px;
        padding: 0 20px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        border-radius: 14px 14px 0px 14px;
      }
      .img-box {
        width: 235px;
        height: 200px;
      }
      .blod {
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        margin-top: 42px;
      }
      .explain {
        text-align: center;
        width: 100%;
        font-size: 14px;
        margin-left: 0;
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .quota-main {
    width: 100%;
    min-height: 500px;
    .quota-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 40px);
      padding: 0 20px;
      .title {
        display: none;
      }
      .title-box {
        display: block;
        width: 182px;
      }
      .img-box {
        width: 182px;
        height: 154px;
        margin-top: 20px;
      }
      .blod {
        font-size: 14px;
      }
      .explain {
        text-align: center;
        width: 60%;
        font-size: 14px;
        margin-left: 0;
        margin-right: 0;
        line-height: 24px;
      }
    }
  }
}
</style>